exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ekibimiz-index-tsx": () => import("./../../../src/pages/ekibimiz/index.tsx" /* webpackChunkName: "component---src-pages-ekibimiz-index-tsx" */),
  "component---src-pages-faaliyet-alanlari-index-tsx": () => import("./../../../src/pages/faaliyet-alanlari/index.tsx" /* webpackChunkName: "component---src-pages-faaliyet-alanlari-index-tsx" */),
  "component---src-pages-iletisim-tsx": () => import("./../../../src/pages/iletisim.tsx" /* webpackChunkName: "component---src-pages-iletisim-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-makaleler-index-tsx": () => import("./../../../src/pages/makaleler/index.tsx" /* webpackChunkName: "component---src-pages-makaleler-index-tsx" */),
  "component---src-pages-prismic-blog-url-tsx": () => import("./../../../src/pages/{PrismicBlog.url}.tsx" /* webpackChunkName: "component---src-pages-prismic-blog-url-tsx" */),
  "component---src-pages-prismic-categories-uid-tsx": () => import("./../../../src/pages/{PrismicCategories.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-categories-uid-tsx" */),
  "component---src-pages-prismic-static-pages-uid-tsx": () => import("./../../../src/pages/{PrismicStaticPages.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-static-pages-uid-tsx" */),
  "component---src-pages-prismic-team-members-uid-tsx": () => import("./../../../src/pages/{PrismicTeamMembers.uid}.tsx" /* webpackChunkName: "component---src-pages-prismic-team-members-uid-tsx" */)
}

